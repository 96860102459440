.CustomMessageForm-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  .CustomMessageForm-container h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
  }
  
  .CustomMessageForm-form {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .CustomMessageForm-label {
    display: flex;
    flex-direction: column;
    color: #444;
    font-size: 0.9rem;
  }
  
  .CustomMessageForm-checkbox-label {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .CustomMessageForm-input,
  .CustomMessageForm-select,
  .CustomMessageForm-textarea {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    box-sizing: border-box;
    background-color: white;
  }
  
  .CustomMessageForm-checkbox {
    margin: 0;
  }
  
  .CustomMessageForm-textarea.form_data_textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .CustomMessageForm-select {
    cursor: pointer;
  }
  
  .CustomMessageForm-input:focus,
  .CustomMessageForm-textarea:focus,
  .CustomMessageForm-select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }
  
  .CustomMessageForm-container p {
    margin: 5px 0;
    font-weight: bold;
  }
  
  .CustomMessageForm-button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .CustomMessageForm-send-button,
  .CustomMessageForm-clear-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    width: 40%; 
    max-width: 120px;
  }
  
  .CustomMessageForm-clear-button {
    background-color: #6c757d;
  }
  
  .CustomMessageForm-send-button:hover,
  .CustomMessageForm-clear-button:hover {
    background-color: #0056b3;
  }
  
  .CustomMessageForm-clear-button:hover {
    background-color: #5a6268;
  }
  
  .CustomMessageForm-send-button:active,
  .CustomMessageForm-clear-button:active {
    background-color: #004085;
  }
  
  .CustomMessageForm-clear-button:active {
    background-color: #4b5359;
  }