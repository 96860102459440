body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  margin: 0;
  padding: 0;
  background-color: #eee;
  overflow: hidden;
}

#map {
  height: 800px;
}

#map img {
  margin: 0 auto;
  display: block;
}

.active-nav {
  border-bottom: 3px solid #ea7504;
  border-radius: 0;
}


.geotab-status label {
  line-height: 31px;
  font-weight: bold;
}

.geotab-status .vehicle-controls {
  margin-bottom: 0;
}

#web_ble {
  padding-bottom: 10px;
}

.loc-long-input {
  border: none;
  border-color: none;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #eee;
}

.lat-long-btn {
  border: none;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.bold {
  font-weight: bold;
}

.location {
  padding-bottom: 0.5em;
}

.location-container {
  padding-top: 1em;
  margin-bottom: 0.5em;
}

#LoginCard {
  position: absolute;
  left: 40%;
  top: 10%;
}

.formTextInput {
  display: flex;
  justify-content: space-between;
  width: 300px
}
