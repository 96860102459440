.sticky {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  padding: 1em;
}

.tabs-section {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  margin-bottom: 20px;
  padding-top: 5px;
}

.tabs {
  list-style: none;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin: 0;
}

.tabs li {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  transition: background-color 0.3s ease;
}

.tabs li:hover {
  background-color: #f0f0f0;
}

.tabs li.active {
  background-color: #007bff;
  color: white;
  border-radius: 4px 4px 0 0;
}

.tab-content {
  padding: 0;
}

.client-key-search {
  position: relative;
}

.autocomplete-container {
  position: relative;
  display: inline-block;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px 12px;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.test-scripts-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.test-scripts-header {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.split-container {
  display: flex;
  gap: 20px;
  height: calc(100vh - 100px);
}

.left-panel {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.right-panel {
  flex: 1;
  background-color: #f8f9fa;
  border-left: 1px solid #ddd;
  padding: 15px;
  overflow-y: auto;
}

.controls-section {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  max-width: 400px;
}

.input-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.uniform-width {
  width: 200px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.input-label span {
  min-width: 80px;
  font-weight: 550;
  color: #333;
}

.select-input,
.text-input {
  max-width: -webkit-fill-available;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  background: #fff;
}

.text-input {
  width: 300px;
}

.info-text {
  margin: 10px 0 0;
  color: #666;
  font-size: 14px;
}

.response-section {
  margin-bottom: 20px;
}

.response-container {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  padding: 10px;
}

.response-item {
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  font-family: monospace;
  color: #444;
}

.response-item:last-child {
  border-bottom: none;
}

section {
  margin-bottom: 30px;
}

h2 {
  color: #333;
  margin-bottom: 15px;
  font-size: 20px;
}

.action-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.action-column {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.clear-button {
  padding: 6px 12px;
  margin-left: 1em;
  background: #e8e8e8;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s;
}

.clear-button:hover {
  background: #e0dfdf;
}

.loading-indicator {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .input-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-row {
    grid-template-columns: 1fr;
  }
}