/* ActionRequestForm.css */

.action-request-form {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    width: 100%;
  }
  
  .action-request-form h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
  }
  
  .actionRequest-form-container {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .actionRequest-form-label {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #444;
  }
  
  .actionRequest-form-label span {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .actionRequest-checkbox-label {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .actionRequest-text-input,
  .actionRequest-select-input,
  .actionRequest-textarea-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    box-sizing: border-box;
    background-color: white;
  }
  
  .actionRequest-text-input:focus,
  .actionRequest-select-input:focus,
  .actionRequest-textarea-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }
  
  .actionRequest-text-input:disabled,
  .actionRequest-select-input:disabled,
  .actionRequest-textarea-input:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  
  .actionRequest-checkbox-input {
    margin: 0;
  }
  
  .actionRequest-textarea-input {
    resize: vertical;
    min-height: 80px;
  }
  
  .actionRequest-button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .actionRequest-send-button,
  .actionRequest-clear-button {
    background-color: #007bff;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    width: 40%;
    max-width: 100px;
  }
  
  .actionRequest-clear-button {
    background-color: #6c757d;
  }
  
  .actionRequest-send-button:hover,
  .actionRequest-clear-button:hover {
    background-color: #0056b3;
  }
  
  .actionRequest-clear-button:hover {
    background-color: #5a6268;
  }
  
  .actionRequest-send-button:active,
  .actionRequest-clear-button:active {
    background-color: #004085;
  }
  
  .actionRequest-clear-button:active {
    background-color: #4b5359;
  }
  
  .actionRequest-error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 0;
  }