#drawer {
    box-shadow: 0 0 10px 0 grey;
    background-color: #eee;
    position: relative;
    z-index: 5;
}

.hidden {
    visibility: hidden;
    height: 0;
}

.drawer__toggle_dropdown {
    visibility: visible;
    height: fit-content;
    border: none;
    background-color: transparent;
}

.drawer__toggle_dropdown i {
    padding-top: 0.7em;
    padding-left: 0.7em;
    font-size: 28px;
    cursor: pointer;
}

.drawer__contents {
    display: flex;
    justify-content: space-evenly;
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: 1.25em;
    z-index: 100;
}

.drawer__title {
    font-weight: bold;
    font-size: 24px;
    margin-top: 0.2em;
    margin-bottom: 0.8em;
}

.drawer__text_input {
    border-style: none;
    border-radius: 10px;
    padding: 0.45em;
    margin-bottom: 0.7em;
}

.drawer__geotab {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}

.drawer__orange_btn {
    margin-top: 0.45em;
    border: 3px solid #E87722;
    background-color: #E87722;
    padding: 0.45em 1.25em 0.45em 1.25em;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    width: fit-content;
}

.drawer__orange_btn:hover {
    background-color: #c4651c;
    border-color: #c4651c;
}

.drawer__drive {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.drawer__location_container {
    display: flex;
    padding-left: 1em;
}

.drawer__sub_container {
    display: flex;
    flex-direction: column;
    margin-left: 1.5em;
}


.drawer__toggle_container {
    display: flex;
    justify-content: center;
}

.drawer__toggle {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7em;
}

.drawer__toggle_item  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7em;
}

#drawer label {
    margin-right: 0.5em;
}

.drawer__transparent_blue_btn {
    background-color: transparent;
    border: 3px solid #1B365D;
    border-radius: 10px;
    padding: 0.65em 1.25em 0.65em 1.25em;
    cursor: pointer;
}

.drawer__transparent_blue_btn:hover {
    background-color: #1B365D;
    color: white;
}

.drawer__ios {
  margin-top: 0.5em;
}

.drawer__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1em;
}

.drawer__help_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.drawer__help_item {
    margin-bottom: 0.3em;
}

.drawer__help_link {
    color: #E87722;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}