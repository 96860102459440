.TestScriptButtons-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.TestScriptButtons-container h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
}

.TextScriptButton-li,
.FormScriptButton-li {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: nowrap;
}

button.button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  flex-grow: 1;
  text-align: left;
  min-width: 200px; 
  white-space: normal;
  word-wrap: break-word;
}

button.button:hover {
  background-color: #0056b3;
}

button.edit {
  background-color: #F56600;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  width: 60px;
  text-align: center;
  flex-shrink: 0;
}

button.edit:hover {
  background-color: #522D80;
}

.TextScriptButton-form,
.FormScriptButton-form {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.TextScriptButton-form label,
.FormScriptButton-form label {
  display: block;
  margin: 15px 0;
  color: #444;
  font-size: 0.9rem;
}

.TextScriptButton-form input[type="text"],
.FormScriptButton-form input[type="text"],
.TextScriptButton-form textarea,
.FormScriptButton-form textarea,
.TextScriptButton-form select,
.FormScriptButton-form select {
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.TextScriptButton-form input[type="checkbox"],
.FormScriptButton-form input[type="checkbox"] {
  margin: 5px 10px 5px 0;
  vertical-align: middle;
}

.TextScriptButton-form textarea,
.FormScriptButton-form textarea {
  resize: vertical;
  min-height: 80px;
}

.TextScriptButton-form select,
.FormScriptButton-form select {
  background-color: white;
  cursor: pointer;
}

.TextScriptButton-form input[type="text"]:focus,
.FormScriptButton-form input[type="text"]:focus,
.TextScriptButton-form textarea:focus,
.FormScriptButton-form textarea:focus,
.TextScriptButton-form select:focus,
.FormScriptButton-form select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.TextScriptButton-form p,
.FormScriptButton-form p {
  margin: 5px 0;
  font-weight: bold;
}