#navbar {
    padding: 0;
    margin: 0;
    width: 100vw;
    background-color: #1B365D;
    color: white;
    box-shadow: 1px 1px 5px 1px grey;
    position: relative;
    z-index: 10;
}

.navbar__nav_links {
    padding-left: 5em;
    padding-right: 5em;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
}

.navbar__nav_link {
    padding: 1em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar__nav_link:hover {
    color: #ddd;
}

.navbar__underline {
    margin-top: 5px;
    height: 3px;
    border-radius: 10px;
    width: 80%;
}

.navbar__underline.active {
    background-color: orange;
}

.navbar__logout {
  position: absolute;
  right: 5%;
}
