#map__container {
    width: 100vw;
    height: 90vh;
    background-color: white;
    position: relative;
    z-index: 2;
}

.map__overlay {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1em;
    margin-top: 1em;
    width: 200px;
    background-color: rgb(200,200,200, 0.5);
    padding: 0.8em 1em 0.8em 1em;
    border-radius: 10px;
    z-index: 100;
    box-shadow: 2px 2px 4px 0px grey;
}

.map__overlay_item {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5em;
}

.map__overlay_toggle {
    cursor: pointer;
}