.user-refresh-form {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    width: 100%;
}

.user-refresh-form h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
}

.userRefresh-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.userRefresh-input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.userRefresh-input-label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    color: #444;
}

.userRefresh-input-label span {
    font-weight: bold;
}

.userRefresh-text-input {
    width: 200px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    box-sizing: border-box;
}

.userRefresh-text-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.userRefresh-send-button {
    background-color: #007bff;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.userRefresh-send-button:hover {
    background-color: #0056b3;
}

.userRefresh-send-button:active {
    background-color: #004085;
}